import { FormFieldSubTypes, FormFieldTypes } from '../enums/FormFieldTypes';

//Hard coded value corresponds to phase where prouduct interest is selected for GTM Tracking values
export const productInterestSequence = 'ProfileDetailsPhase_1';

export enum alignmentOptions {
  left = 'flex-start',
  center = 'center',
}

export type EquipmentItem = {
  PropertyKey: string;
  Label: string;
  Maximum: string;
  ImageUrl: string;
};

export type CachedItem = {
  questionGroupId: number;
  id: number;
  value: any;
};

export type FormPagePropertiesModel = {
  formPageId: number;
  pageProperties: Array<any>;
};

export type QuestionGroup = {
  id: number;
  formPageId: number;
  name: string;
  ordinal: number;
  headerHtml: string;
  footerHtml: string;
  questions: Array<Question>;
  allowLocalCache: boolean;
  infoHtml: string | null;
};

export type Question = {
  id: number;
  formFieldQuestionGroupId: number;
  parentFormFieldOptionId: number | null;
  parentFormQuestionId?: number;
  label: string;
  ordinal: number;
  helperText: string | null;
  fontAwesomeIcon: string | null;
  fieldType: FormFieldTypes;
  fieldDefinition: string;
  isRequired: boolean;
  placeholder: string | null;
  options: Array<FormFieldOption>;
  infoHtml: string | null;
  hasVisibilityDependency: boolean;
  fieldValue?: FieldValue;
};

export type FormFieldOption = {
  id: number;
  formFieldQuestionId: number;
  isDefaultOption: boolean;
  label: string;
  value: string;
  questions: Array<Question>;
  ordinal: number;
  infoHtml?: string;
};

export type FieldValue = {
  autoComplete: string;
  fieldType: FormFieldTypes;
  fieldDefinition: string;
  isRequired: boolean;
  label: string;
  options: Array<FormFieldOption>;
  ordinal: number;
  questionId: number;
  questionParentId?: number;
  questionGroupId: number;
  questionGroupOrdinal: number;
  value: any;
  confirmationValue?: any;
  confirmationError?: boolean;
  isSensitive?: boolean;
  locked?: boolean;
  referenceData?: Array<any>;
  validationError: boolean;
  validationErrorText: string;
  visible?: boolean;
  hasVisibilityDependency?: boolean;
};

export type LocationFieldDefinition = {
  StreetAddress: { PropertyKey: string };
  Zip: { PropertyKey: string };
  City: { PropertyKey: string };
  State: { PropertyKey: string };
};

export type ProportionOption = {
  option: string;
  value: number | null;
  userModified: boolean;
};

export type EquipmentQuantity = {
  quantity: Array<number>;
};

export type ValidationResult = {
  validationError: boolean;
  validationErrorText: string;
  confirmationError?: boolean;
  validationStatus?: any;
};

export type ProportionOptionJSON = {
  Label: string;
  PropertyKey: string;
};

export type JsonDocumentResponse = {
  document: string;
  name: string;
};

export type MCC = {
  Code: string;
  Name: string;
};

export type PartnerEcommSoftware = {
  AffiliateId: string;
  PartnerEcommSoftware: string;
};

export type PartnerGpiSoftware = {
  PartnerProductVersionId: string;
  PartnerGpiSoftware: string;
  RecommendedOption?: string;
};

export type ReferenceData = PartnerEcommSoftware | PartnerGpiSoftware | MCC;

export type MatrixDocument = {
  Title: string;
  Categories: Array<string>;
  Table: Array<MatrixRow>;
  Disclaimer?: string;
};

export type MatrixRow = {
  Title: string;
  Values: Array<string>;
};

export type QuestionProps = {
  label?: string;
  hideLabel?: boolean;
  fieldType: FormFieldTypes;
  fieldSubType?: FormFieldSubTypes;
  fieldDefinitionJson: any;
  onChange: (questionId: number, value: any, shouldValidate: boolean, confirmationValue?: any) => void;
  value: any;
  referenceData?: Array<any>;
  required?: boolean;
  helperText?: string | null;
  placeholder?: string | null;
  infoHtml: string | null;
  renderForSummary?: boolean;
  disabled?: boolean;
};

export const EmailValidatorId = 'EmailValidator';
export const EmailPropertyKey = 'Email';
export const LockedPropertyKeys = [EmailPropertyKey];

export type BankAccountValidatorProps = {
  RoutingId: string;
  AccountId: string;
  AccountConfirmationId: string;
};

export const BankAccountValidatorOptions: BankAccountValidatorProps = {
  RoutingId: 'RoutingNumber',
  AccountId: 'AccountNumber',
  AccountConfirmationId: 'AccountConfirmation',
};
